import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HasId } from "../model/generics";
import { ISample } from "../model/sample.model";
import { createUrl, getPublicRoutesHeaders } from "./api.util";
import { GenericApi } from "./generic.api";

// Convenience Type
type T = ISample;

@Injectable()
export class SampleApi extends GenericApi<T> {
  public path = "sample";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  get = (id: number | string): Observable<T & HasId<T>> => {
    return this.httpClient.get<T & HasId<T>>(createUrl(this.path, id), {
      headers: getPublicRoutesHeaders(),
    });
  };
}
