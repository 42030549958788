import { FormInstanceContactType } from "src/app/main/model/forms/form-instance-contact.model";

//These are used to find the includes in the api query request so they can be amended from within a service ----->
//e.g
// const formGroupInstance = defaultDealQuery.include[ApiAssociationDealIncludesIndex.formGroupInstance] as IDBInclude;
// formGroupInstance.required = formGroupInstanceRequired;
// formGroupInstance.filter = { isDeleted: 0 };
export enum ApiAssociationDealIncludesIndex {
  pipelineStage = 0,
  contacts = 1,
  companies = 2,
  formGroupInstance = 3,
}

// <-------------

export const BENCHMARK_AND_BENCHMARKFILTER_JSON_PATH = "include[0]";
export const BENCHMARKFILTER_AND_BENCHMARK_JSON_PATH = "include[0]";
export const FORMGROUPCOMPANY_AND_BENCHMARK_JSON_PATH = "include[0].include[0].include[0]";
export const FORMINSTANCE_AND_BENCHMARK_JSON_PATH = "include[0]";
export const FORMANSWER_AND_BENCHMARK_JSON_PATH = "include[0].include[0]";

export const API_ASSOCIATIONS = {
  alert: {
    include: [{ name: "user" }],
  },
  benchmark: {
    include: [{ name: "benchmarkFilter" }],
  },
  benchmarkFilter: {
    include: [
      { name: "benchmark" },
      { name: "benchmarkJob", required: true },
      {
        name: "practitionerTypes",
        include: [
          { name: "careLevel" },
          { name: "practitionerLevel" },
          { name: "specialityLevel" },
          {
            name: "practitionerType",
            include: [
              { name: "careLevel" },
              { name: "practitionerLevel" },
              { name: "specialityLevel" },
            ],
          },
        ],
      },
      { name: "benchmarkFilterFormTemplateVersions", include: [{ name: "formTemplate" }] },
    ],
  },
  benchmarkData: {},
  benchmarkFilterComparisonData: {
    include: [
      {
        name: "benchmark",
        include: [{ name: "benchmarkData", filter: { order: 1 }, required: true }], //only get the first row of benchmarkData
      },
      { name: "benchmarkJob", required: true },
      {
        name: "practitionerTypes",
        include: [{ name: "careLevel" }, { name: "practitionerLevel" }],
      },
    ],
  },
  benchmarkDataAndAnswers: {
    include: [{ name: "qVariant" }, { name: "qEnumValueVariant" }, { name: "questionGroup" }],
  },
  benchmarkFilterFTE: {},
  benchmarkRemovedData: {},
  benchmarkJob: {},
  careLevel: { filter: { hidden: 0 }, sortBy: "displayOrder" },
  dealOnly: {
    deal: {},
  },
  dealWithoutAnswers: {
    deal: {
      //don't change the order of the includes, they are needed in a particular order in the advanced search
      include: [
        { name: "pipelineStage" },
        { name: "contacts" },
        { name: "companies" },
        {
          name: "formGroupInstances",
          include: [
            {
              name: "formGroupInstanceDocuments",
              include: [
                {
                  name: "document",
                  include: [
                    {
                      name: "documentType",
                    },
                  ],
                },
              ],
            },
            {
              name: "formInstances",
              include: [{ name: "formInstanceContacts" }],
            },
            { name: "workflowStep", attributes: ["id", "name"] },
          ],
        },
      ],
    },
  },
  dealWithAnswers: {
    deal: {
      //don't change the order of the includes, they are needed in a particular order in the advanced search
      include: [
        { name: "pipelineStage" },
        { name: "contacts" },
        { name: "companies" },
        {
          name: "formGroupInstances",
          include: [
            {
              name: "formGroupInstanceDocuments",
              include: [
                {
                  name: "document",
                  include: [
                    {
                      name: "documentType",
                    },
                  ],
                },
              ],
            },
            {
              name: "formInstances",
              include: [
                { name: "formInstanceContacts" },
                {
                  name: "formResponses",
                  include: [
                    { name: "formAnswers", include: [{ name: "reviewedBy" }] },
                    { name: "reviewedBy" },
                  ],
                },
              ],
            },
            { name: "formGroupOfflineResponses", required: false },
            { name: "workflowStep", attributes: ["id", "name"] },
          ],
        },
      ],
    },
  },
  environment: {},
  formAnswersAndBenchmark: {
    //!don't change without checking FORMANSWER_AND_BENCHMARK_JSON_PATH
    include: [
      {
        name: "formResponse",
        include: [
          {
            name: "benchmarks",
            required: true,
          },
        ],
      },
      {
        name: "formTemplateQuestion",
        include: [
          {
            name: "questionVariant",
            include: [
              {
                name: "question",
                include: [
                  {
                    name: "questionRangeGroup",
                    include: [
                      {
                        name: "qRangeGroupWs",
                        include: [
                          {
                            name: "qRangeGroupWValues",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  formGroupInstance: {
    include: [
      { name: "deal" },
      { name: "formGroupCompany" },
      {
        name: "formInstances",
        include: [{ name: "formInstanceContacts" }, { name: "formResponses" }],
      },
      { name: "workflowStep" },
    ],
  },
  formGroupStandard: {
    formGroup: {
      limit: 50,
      include: [
        {
          name: "documents",
          include: [{ name: "documentType" }],
        },
        {
          name: "formTemplateGroups",
          include: [{ name: "formTemplate" }],
        },
      ],
      sortBy: "name",
    },
  },
  formGroupCompanyAndBenchmark: {
    //!don't change without checking FORMGROUPCOMPANY_AND_BENCHMARK_JSON_PATH
    include: [
      {
        name: "formGroupInstance",
        include: [
          {
            name: "formInstances",
            required: true,
            include: [{ name: "benchmarks", required: true }],
          },
        ],
      },
    ],
  },
  formGroupDocumentOnly: {
    formGroup: {
      include: [
        {
          name: "documents",
          include: [{ name: "documentType" }],
        },
      ],
    },
  },
  formInstanceAndBenchmark: {
    //!don't change without checking FORMINSTANCE_AND_BENCHMARK_JSON_PATH
    include: [
      {
        name: "benchmarks",
        required: true,
      },
      {
        name: "formGroupInstance",
        include: [
          {
            name: "formGroupCompany",
          },
        ],
      },
      {
        name: "formInstanceContacts",
        filter: { type: FormInstanceContactType.Subject },
      },
    ],
  },
  formTemplate: {},
  pipeline: {
    include: [
      {
        name: "pipelineStages",
        sortBy: "workflowStepId",
      },
    ],
  },
  practitionerLevel: { filter: { hidden: 0 }, sortBy: "displayOrder" },
  practitionerType: {
    filter: { hidden: 0 },
    include: [{ name: "careLevel" }, { name: "practitionerLevel" }, { name: "specialityLevel" }],
  },
  rurality: {},
  specialityLevel: { filter: { hidden: 0 }, sortBy: "displayOrder" },
  user: {
    filter: { id: "" },
    include: [{ name: "roles", include: [{ name: "permissions" }] }],
  },
};

export const PIPELINE_STATUS = {
  ApplicationReceived: "23734097",
  SurveySent: "23734098",
  SurveysReceived: "23734099",
  ReportSent: "23734100",
  ClosedLost: "23734102",
  CommentsCheckedDate: "26435414",
};

export const SCHEDULER_REFRESH_SERVICES = {
  CRM: "CRM",
};
